<template>
  <div>
    <div v-if="tags">
      <p class="heading-3 mb-4" data-selector="tags-lp-title">
        {{ i18n(translations.soundAndFurious) }}
        {{ i18n(translations.popularSearches) }}
      </p>
      <div class="flex flex-wrap gap-8">
        <RevButtonTiny
          v-for="tag in tags"
          :key="tag.slug"
          :to="tag.link"
          variant="primary"
          >{{ tag.title }}</RevButtonTiny
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonTiny } from '@ds/components/ButtonTiny'

import { useProductListTags } from '../composables/useProductListTags'

import translations from './ProductTags.translations'

const i18n = useI18n()

const props = defineProps<{
  id: string
}>()

const { tags } = await useProductListTags(props.id)
</script>
