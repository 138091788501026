<template>
  <div v-if="parent.concat(children).length > 0">
    <div class="hidden lg:block">
      <h3 class="heading-3 mb-16">
        {{ i18n(translations.landingNavigationSeoTitle) }}
      </h3>
      <ul v-for="(item, index) in parent" :key="item.title">
        <li :style="`margin-left: ${index * 1}rem`">
          <RevLink
            :class="{ '!font-weight-body-1': item.id !== pageId }"
            :to="{
              page: PRODUCT_LIST_ROUTES.PRODUCT_LIST,
              params: {
                slugV2: item.link.params.slugV2,
                uuid: item.link.params.uuid,
              },
            }"
            :underlined="false"
            @click="trackClick(toTrackingData(item.title))"
            >{{ item.title }}
          </RevLink>
          <template v-if="index === parent.length - 1">
            <ul class="ml-16">
              <li v-for="child in visibleChildren" :key="child.title">
                <h4>
                  <RevLink
                    :class="{ '!font-weight-body-1': child.id !== pageId }"
                    :to="{
                      page: PRODUCT_LIST_ROUTES.PRODUCT_LIST,
                      params: {
                        slugV2: child.link.params.slugV2,
                        uuid: child.link.params.uuid,
                      },
                    }"
                    :underlined="false"
                    @click="trackClick(toTrackingData(child.title))"
                  >
                    {{ child.title }}
                  </RevLink>
                </h4>
              </li>
            </ul>
          </template>
        </li>
      </ul>
      <RevLink v-if="isExpandable" class="ml-16 mt-16" @click="toggle">
        {{
          isExpanded ? i18n(translations.viewLess) : i18n(translations.viewMore)
        }}
      </RevLink>
    </div>
    <ul class="flex snap-x gap-8 overflow-scroll scrollbar-none lg:hidden">
      <li
        v-for="item in parent.concat(children)"
        :key="item.id"
        class="inline-block snap-start lg:hidden"
      >
        <RevButtonTiny
          :to="{
            page: PRODUCT_LIST_ROUTES.PRODUCT_LIST,
            params: {
              slugV2: item.link.params.slugV2,
              uuid: item.link.params.uuid,
            },
          }"
          :underlined="item.id === pageId"
          variant="secondary"
          @click="trackClick(toTrackingData(item.title))"
        >
          {{ item.title }}
        </RevButtonTiny>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButtonTiny } from '@ds/components/ButtonTiny'
import { RevLink } from '@ds/components/Link'

import { useProductListSideNavigation } from '../composables/useProductListSideNavigation'
import { ROUTES as PRODUCT_LIST_ROUTES } from '../route-names'

import translations from './SideNavigation.translations'

const props = defineProps<{
  pageId: string
}>()

const { parent, visibleChildren, children, isExpanded, toggle, isExpandable } =
  await useProductListSideNavigation(props.pageId)

const i18n = useI18n()

const { trackClick } = useTracking()
function toTrackingData(name: string) {
  return {
    name,
    zone: 'product list > seo nav',
  }
}
</script>
