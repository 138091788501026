import { useRequestURL, useRouter } from '#imports'
import { computed } from 'vue'
import type { Router } from 'vue-router'

import type { Article } from '@backmarket/http-api/src/api-specs-acquisition/buying-guide/article'
import { getPLPBuyingGuides } from '@backmarket/http-api/src/api-specs-acquisition/buying-guide/buying-guide'
import type { ArticleCard } from '@backmarket/nuxt-layer-cms/ArticleCard/ArticleCard.types'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'

import { buildGetArticleHrefFn } from '~/scopes/article/helpers/buildGetArticleHrefFn'

const guidesToArticles = (
  guide: Article,
  router: Router,
  currentUrl: URL,
): ArticleCard => {
  const getLink = buildGetArticleHrefFn({ router, currentUrl })

  return {
    title: guide.title,
    category: guide.tagline,
    text: guide.text,
    id: `${guide.id}`,
    imageSrc: guide.image.src,
    imageAlt: guide.image.alt,
    link: getLink(guide.link),
  }
}

export function usePLPArticles(id: string) {
  const { data: guides, pending } = useHttpFetch(getPLPBuyingGuides, {
    pathParams: { id },
  })

  const router = useRouter()
  const currentUrl = useRequestURL()

  const data = computed(() =>
    guides.value
      ? guides.value.map((guide) => guidesToArticles(guide, router, currentUrl))
      : [],
  )

  return {
    data,
    pending,
  }
}
