import type { LinkInternal } from '../../api-models/LinkInternal'
import { createHttpEndpoint } from '../../utils'

export interface NavigationItem {
  id: string
  slug: string
  title: string
  link: LinkInternal
}

export interface ProductListNavigation {
  parent_nav: NavigationItem[]
  children: NavigationItem[]
}

/**
 * Returns the product list page navigation

 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/navigation-api/definition#tag/topsales/operation/getNavigationTopsalesV2}
 */
export const getProductListNavigation =
  createHttpEndpoint<ProductListNavigation>({
    method: 'GET',
    operationId: 'getNavigationLandinPage',
    path: '/bm/navigation/landing-page',
  })
