export default {
  soundAndFurious: {
    id: 'sound_and_furious',
    defaultMessage: '"The sound and the fury"',
  },
  popularSearches: {
    id: 'popular_search',
    defaultMessage: 'our most popular searches',
  },
}
