import { type MaybeRef, computed } from 'vue'

import { productListPageAPI } from '@backmarket/http-api'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'

export async function useProductListPage(id: MaybeRef<string>) {
  const { data } = await useHttpFetch(productListPageAPI.getProductListPage, {
    queryParams: { id },
  })

  const transformedData = computed(() => {
    if (data.value) {
      const marketingContent =
        data.value.marketing_content &&
        data.value.marketing_content?.list?.link?.url &&
        data.value.marketing_content?.list.urls?.large &&
        data.value.marketing_content?.card?.urls?.small
          ? {
              alt: data.value.marketing_content?.list.alt,
              url: data.value.marketing_content?.list.link?.url,
              srcLarge: data.value.marketing_content?.list.urls?.large,
              srcSmall: data.value.marketing_content?.card.urls?.small,
            }
          : undefined

      return {
        title: data.value.displayed_title_plural,
        displayedTitle: data.value.displayed_title,
        tagline: data.value.tagline,
        seoText: data.value.text_seo,
        marketingContent,
        seoTitle: data.value.title_seo,
        links: data.value.links,
        breadcrumb: data.value.breadcrumb,
        popularRelatedLinks: data.value.popular_related_links,
        landingType: data.value.landing_type,
      }
    }

    return null
  })

  return transformedData
}
