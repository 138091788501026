export default {
  viewMore: {
    id: 'landing_navigation_seo_view_more',
    defaultMessage: 'View more',
  },
  viewLess: {
    id: 'landing_navigation_seo_view_less',
    defaultMessage: 'View less',
  },
  landingNavigationSeoTitle: {
    id: 'landing_navigation_seo_title',
    defaultMessage: 'Categories',
  },
}
